import { useWeb3React } from '@web3-react/core';
import { BetResultModal } from 'components/specs';
import { Shape, SPUN_GAME_IDS } from 'lib/constants';
import { useStores } from 'providers/StoreProvider';
import { useEffect, useState } from 'react';
import RootStore from 'stores/RootStore';
import { WheelSpinnerProps } from './index';

export const TheWheel = ({ items, onSelectItem }: WheelSpinnerProps) => {
  const { account } = useWeb3React();
  const [selectedItem, setSelectedItem] = useState<Nullable<number>>(null);
  const [timerId, setTimerId] = useState<NodeJS.Timeout>();
  const [betResultModal, setBetResultModal] = useState<boolean>(false);
  const [betResultAmount, setBetResultAmount] = useState<string>('0');
  const [betWon, setBetWon] = useState<boolean>(false);

  const { chainStore }: RootStore = useStores();

  useEffect(() => {
    chainStore.contract.on('OutcomeSet', (requestId: any, gameId: any, outCome: Shape) => {
      console.log('outCome', outCome);
      const _outComeItem = items.find((item: any) => item.value === outCome);
      if (_outComeItem) {
        const gameIds = localStorage.getItem(SPUN_GAME_IDS) || '';
        const splitGameIds = gameIds.split(',') || [];
        if (!splitGameIds.includes(gameId.toString())) {
          localStorage.setItem(
            SPUN_GAME_IDS,
            splitGameIds.length ? `${gameIds},${gameId.toString()}` : gameId.toString()
          );
        }
        setSelectedItem(_outComeItem.id);
        if (onSelectItem) onSelectItem(_outComeItem.id);
        const timerId = setTimeout(() => {
          if (outCome === chainStore.betShape) {
            const amountWonInBnb: number = chainStore.currentBetPrice * (chainStore.betShapeMultilierNumber || 1);
            setBetResultAmount(amountWonInBnb.toString());
            chainStore.setBetResult({ isWon: true, requestId, betAmount: amountWonInBnb, outCome });
            setBetWon(true);
          } else {
            const amountLostInBnb: number = chainStore.currentBetPrice * -1;
            setBetResultAmount(amountLostInBnb.toString());
            chainStore.setBetResult({ isWon: false, requestId, betAmount: amountLostInBnb, outCome });
            setBetWon(false);
          }
          setBetResultModal(true);
          chainStore.loadInitInfos(account);
        }, 6000);
        setTimerId(timerId);
      }
    });
  }, [account, chainStore, chainStore.contract, items, onSelectItem, selectedItem]);

  useEffect(() => {
    return () => {
      if (timerId) clearTimeout(timerId);
    };
  }, [timerId]);

  useEffect(() => {
    setSelectedItem(null);
  }, [chainStore.placeBetHashId]);

  const getWheelVars = () => {
    return {
      '--nb-item': items.length,
      '--selected-item': selectedItem,
    } as React.CSSProperties;
  };

  const getWheelItemVars = (index: number) => {
    return { '--item-nb': index } as React.CSSProperties;
  };

  return (
    <div className="wheel-container scale-[0.8] lg:scale-100 -top-[60px] lg:top-0">
      <div className={`wheel bg-theWheel bg-cover ${selectedItem !== null ? 'spinning' : ''}`} style={getWheelVars()}>
        {items.map((item, index) => (
          <div className="wheel-item" key={index} style={getWheelItemVars(item.id)}>
            <img src={item.icon} className="wheel-item-icon rotate-90 w-full" alt={'wheel_' + item.id} />
          </div>
        ))}
      </div>
      <BetResultModal
        modalShow={betResultModal}
        setModalView={setBetResultModal}
        amount={betResultAmount}
        win={betWon}
      ></BetResultModal>
    </div>
  );
};
