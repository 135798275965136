export const TRANSLATIONS_RU = {
   flag: 'ru',
   header: {
       connect: "Подключить кошелек" 
   },
   footer: {
       placeABet: "Сделать ставку",
       total: "Всего ставок"
   },
   body: {
       wheel: {
           startIn: "Начинать",
           choose: "Выберите сумму ставки"
       }
   }
};
