/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from 'ethers';
import { Provider } from '@ethersproject/providers';
import type { VRFCoordinatorV2Interface, VRFCoordinatorV2InterfaceInterface } from '../VRFCoordinatorV2Interface';

const _abi = [
  {
    inputs: [
      {
        internalType: 'uint64',
        name: 'subId',
        type: 'uint64',
      },
    ],
    name: 'acceptSubscriptionOwnerTransfer',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint64',
        name: 'subId',
        type: 'uint64',
      },
      {
        internalType: 'address',
        name: 'consumer',
        type: 'address',
      },
    ],
    name: 'addConsumer',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint64',
        name: 'subId',
        type: 'uint64',
      },
      {
        internalType: 'address',
        name: 'to',
        type: 'address',
      },
    ],
    name: 'cancelSubscription',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'createSubscription',
    outputs: [
      {
        internalType: 'uint64',
        name: 'subId',
        type: 'uint64',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getRequestConfig',
    outputs: [
      {
        internalType: 'uint16',
        name: '',
        type: 'uint16',
      },
      {
        internalType: 'uint32',
        name: '',
        type: 'uint32',
      },
      {
        internalType: 'bytes32[]',
        name: '',
        type: 'bytes32[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint64',
        name: 'subId',
        type: 'uint64',
      },
    ],
    name: 'getSubscription',
    outputs: [
      {
        internalType: 'uint96',
        name: 'balance',
        type: 'uint96',
      },
      {
        internalType: 'uint64',
        name: 'reqCount',
        type: 'uint64',
      },
      {
        internalType: 'address',
        name: 'owner',
        type: 'address',
      },
      {
        internalType: 'address[]',
        name: 'consumers',
        type: 'address[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint64',
        name: 'subId',
        type: 'uint64',
      },
      {
        internalType: 'address',
        name: 'consumer',
        type: 'address',
      },
    ],
    name: 'removeConsumer',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: 'keyHash',
        type: 'bytes32',
      },
      {
        internalType: 'uint64',
        name: 'subId',
        type: 'uint64',
      },
      {
        internalType: 'uint16',
        name: 'minimumRequestConfirmations',
        type: 'uint16',
      },
      {
        internalType: 'uint32',
        name: 'callbackGasLimit',
        type: 'uint32',
      },
      {
        internalType: 'uint32',
        name: 'numWords',
        type: 'uint32',
      },
    ],
    name: 'requestRandomWords',
    outputs: [
      {
        internalType: 'uint256',
        name: 'requestId',
        type: 'uint256',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint64',
        name: 'subId',
        type: 'uint64',
      },
      {
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'requestSubscriptionOwnerTransfer',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
];

export class VRFCoordinatorV2Interface__factory {
  static readonly abi = _abi;
  static createInterface(): VRFCoordinatorV2InterfaceInterface {
    return new utils.Interface(_abi) as VRFCoordinatorV2InterfaceInterface;
  }
  static connect(address: string, signerOrProvider: Signer | Provider): VRFCoordinatorV2Interface {
    return new Contract(address, _abi, signerOrProvider) as VRFCoordinatorV2Interface;
  }
}
