export const TRANSLATIONS_ZH = {
   flag: 'cn',
   header: {
       connect: "連接錢包" 
   },
   footer: {
       placeABet: "下注",
       total: "總投注"
   },
   body: {
       wheel: {
           startIn: "開始於",
           choose: "選擇投注金額"
       }
   }
};
