import { BET_AMOUNTS } from 'lib/constants';
import { observer } from 'mobx-react';
import { useStores } from 'providers/StoreProvider';
import { useTranslation } from 'react-i18next';
import RootStore from 'stores/RootStore';
import BetAmountButton from '../BetAmountButton';
import { CountdownTimer } from '../CountdownTimer';

export const BetInfoWrapper = observer(() => {
  const { t } = useTranslation();
  const { chainStore }: RootStore = useStores();
  return (
    <div className="bet-info-wrapper top-betInfo text-center flex flex-col justify-center">
      {!chainStore.withInTimeRange ? (
        <p className="text-bet">
          {chainStore.isPlayerAlreadyBet ? t('body.wheel.alreadyBet') : t('body.wheel.wheelSpinCondition')}
        </p>
      ) : (
        <>
          <p className="text-bet">{t('body.wheel.startIn')}</p>
          <CountdownTimer
            seconds={chainStore.remainTime || chainStore.wheelInterval}
            start={chainStore.withInTimeRange}
          />
        </>
      )}

      <p className="text-bet mt-3">{t('body.wheel.choose')}</p>
      <div className="flex justify-center">
        <div className="flex justify-center flex-wrap md:flex-nowrap w-4/5 gap-2 mt-3">
          {BET_AMOUNTS.map((amount, index) => (
            <BetAmountButton
              key={index}
              value={amount}
              isActive={amount === chainStore.currentBetPrice}
              onClick={() => {
                chainStore.setCurrentBetPrice(amount);
              }}
            ></BetAmountButton>
          ))}
        </div>
      </div>
    </div>
  );
});
