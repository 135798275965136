import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { TRANSLATIONS_EN } from "./en/translations";
import { TRANSLATIONS_RU } from "./ru/translations";
import { TRANSLATIONS_ZH } from "./zh/translations";
import { TRANSLATIONS_KO } from "./ko/translations";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: ['en'],
        detection: {
            order: ['localStorage', 'navigator'],
            caches: ['localStorage'],
          },
        resources: {
            en: {
                translation: TRANSLATIONS_EN
            },
            ru: {
                translation: TRANSLATIONS_RU
            },
            zh: {
                translation: TRANSLATIONS_ZH
            },
            ko: {
                translation: TRANSLATIONS_KO
            },
            interpolation: {
                escapeValue: false, // not needed for react as it escapes by default
            },
        }
    });

i18n.changeLanguage("en");
