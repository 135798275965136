import { BNB_SCAN_URL } from 'lib/constants';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

const Success = ({ betType, betAmount, hashId }: { betType: string; betAmount: string; hashId: string }) => (
  <p>
    <span className="font-bold">Bet Accepted</span> Your bet {betType} for {betAmount} Accepted{' '}
    <a href={`${BNB_SCAN_URL}${hashId}`} target="_blank" rel="noreferrer">
      View on BscScan
    </a>
  </p>
);

const Failure = () => (
  <p>
    <span className="font-bold">Bet Declined</span> There was an error processing your payment
  </p>
);

const BetToastMessage = ({
  status,
  betType,
  betAmount,
  hashId,
}: {
  status: string;
  betType?: string;
  betAmount?: string;
  hashId?: string;
}) => {
  useEffect(() => {
    if (status) {
      status === 'success'
        ? toast.success(<Success betType={betType || ''} betAmount={betAmount || ''} hashId={hashId || ''} />, {
            className: 'toast-message-container-success',
            position: 'top-center',
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          })
        : toast.error(<Failure />, {
            className: 'toast-message-container-failure',
            position: 'top-center',
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
    }
  }, [betAmount, betType, hashId, status]);

  return <></>;
};

export default BetToastMessage;
