import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { useIsWalletConnected, useIsWalletConnectedBsc } from 'lib/utils/helpers';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

const ModalBody = ({
  switchNetwork,
  metaMaskConnect,
  walletConnector,
  closeModal,
}: {
  switchNetwork: () => void;
  metaMaskConnect: () => void;
  walletConnector: () => void;
  closeModal: () => void;
}) => {
  const { t } = useTranslation();
  const isWalletConnectedBsc = useIsWalletConnectedBsc();
  const isWalletConnected = useIsWalletConnected();
  return (
    <Transition.Child
      as={Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      <div className="inline-block w-full max-w-md p-6 pb-8 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
        <label className="btn btn-sm btn-ghost absolute right-2 top-2" onClick={closeModal}>
          <XIcon className="h-5 w-5 text-button-surface-dk" />
        </label>

        {isWalletConnected && !isWalletConnectedBsc && (
          <div>
            <Dialog.Title as="h2" className="text-xl text-bold font-large leading-6 text-gray-900">
              {t('header.switchNetwork')}
            </Dialog.Title>
            <div
              className="place-a-bet flex justify-between align-items-center mt-6 p-2.5 px-4 wallet-item rounded-md cursor-pointer"
              onClick={switchNetwork}
            >
              <p>Binance Smart Chain</p>
              <img src="/images/icons/header_bnb.png" width="25" alt={`${t('alt.switch_to')}BSC`} />
            </div>
          </div>
        )}
        {!isWalletConnected && (
          <div>
            <Dialog.Title as="h2" className="text-xl text-bold font-large leading-6 text-gray-900">
              {t('header.selectWallet')}
            </Dialog.Title>
            <div
              className="place-a-bet flex justify-between align-items-center mt-6 p-2.5 px-4 wallet-item rounded-md cursor-pointer"
              onClick={metaMaskConnect}
            >
              <p>MetaMask</p>
              <img src="/images/icons/metamask.png" width="25" alt={`${t('alt.connect_to')}MetaMask`} />
            </div>
            <div
              className="place-a-bet flex justify-between align-items-center mt-6 p-2.5 px-4 wallet-item rounded-md cursor-pointer"
              onClick={walletConnector}
            >
              <p>WalletConnect</p>
              <img src="/images/icons/WalletConnector.svg" width="32" alt={`${t('alt.connect_to')}WalletConnect`} />
            </div>
          </div>
        )}
      </div>
    </Transition.Child>
  );
};

export default ModalBody;
