export const TRANSLATIONS_EN = {
   flag: 'us',
   symbol: {
       circle: 'circle',
       triangle: 'triangle',
       star: 'star',
       umbrella: 'umbrella'
   },
   general: {
       cancel: "Cancel",
   },
   alt: {
       /* These are low priority for translation; they only refer to alt text in images */
       connect_to: "Connect to ", 
       switch_to: "Switch to ", 
       symbol: "Symbol coin",
       logo: "Logo",
       flag_of: "Flag of ",
       multiplier: "multiplier",
       username: "Username",
       wheelArrow: "wheel pointer"
   },
   header: {
       connect: "Connect Wallet",
       selectWallet: "Connect your wallet",
       switchNetwork: "Switch Network",
       claimAll: "Claim Winnings",
   },
   footer: {
       placeABet: "Place a Bet",
       total: "Total bets",
   },
   body: {
       wheel: {
           startIn: "Start in",
           wheelSpinCondition: "Wheel will spin once the next bet has been placed.",
           alreadyBet: "You already placed a bet. Wheel will spin soon.",
           choose: "Choose Bet Amount",
       }
   },
   message: {
    connect_wallet: 'Please connect wallet!',
    already_bet: 'You are already on bet!',
    claimWinningAllSuccess: 'Congratulations! You successfully claim all winnings!',
    claimWinningAllFailure: 'Sorry! Something goes wrong while claiming all winnings!',
   },
   drawer: {
        open: 'Drawer',
       close: "Close",
   }
};
