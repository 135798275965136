import { Popover, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import HeaderButton from './buttons/HeaderButton';
import WalletButton from './buttons/WalletButton';

const Drawer = ({
  account,
  sqmBalance,
  sqmUsd,
  eqxBalance,
  isWalletConnectedBsc,
  setShow,
}: {
  account: string;
  sqmBalance: number;
  sqmUsd: string;
  eqxBalance: string;
  isWalletConnectedBsc: boolean;
  setShow: (show: boolean) => void;
}) => {
  const { t } = useTranslation();

  return (
    <div className="block lg:hidden">
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button className={`${open ? '' : 'text-opacity-90'} bg-transparent px-3 py-2`}>
              <img src="/images/icons/drawer.png" width="32" alt={`${t('drawer.open')}`} />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-50 translate-x-1"
              enterTo="opacity-100 translate-x-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-x-0"
              leaveTo="opacity-0 translate-x-1"
            >
              <Popover.Panel className="fixed z-20 top-0 right-0">
                {({ close }) => (
                  <div className="relative flex overflow-hidden min-w-[300px] h-screen bg-drawer p-5">
                    <img
                      src="/images/icons/close.png"
                      width="16"
                      alt={`${t('drawer.close')}`}
                      className="absolute top-4 right-4"
                      onClick={() => close()}
                    />
                    {!isWalletConnectedBsc ? (
                      <WalletButton onClick={() => setShow(true)} className="mt-12" />
                    ) : (
                      <div className="mt-10 flex flex-col gap-6 justify-start">
                        <div className="rounded-full border border-nav-edge px-2 py-1">
                          <div className="btn-group no-animation noHover flex justify-center p-0">
                            <HeaderButton
                              text={account}
                              src="/images/icons/metamask.png"
                              alt="metamask address"
                              size="h-xs"
                              className="h-4 min-h-[2rem]"
                            />
                          </div>
                        </div>
                        <div className="btn-group no-animation noHover">
                          <HeaderButton
                            text={(+eqxBalance).toFixed(4)}
                            symbol="BNB"
                            src="/images/icons/drawer_bnb.png"
                            alt="sqm"
                            className="p-0"
                          />
                        </div>
                        <div className="btn-group no-animation noHover">
                          <HeaderButton
                            text={sqmBalance.toFixed(2)}
                            usdBalance={sqmUsd}
                            symbol="SQM"
                            src="/images/icons/sqm_icon_sm.png"
                            alt="bnb"
                            className="p-0"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

export default Drawer;
