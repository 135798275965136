//cspell:ignore squircle
import { Menu } from '@headlessui/react';
import { LangFlag } from './LangFlag';
import { StringOfLength } from './stringOfLength';

/**
 * Display a country info spring with flag icon in a <Menu.Item>.
 *
 * @remarks
 *
 * Since country codes are only two digits long, the template values of <2,2> should _not_ change.
 * Elsewhere in the code, use CC_LEN to represent this value.
 */
export interface LangMenuItemProps {
  label: string;
  code: StringOfLength<2, 2>;
  flag: StringOfLength<2, 2>;
  flagSize?: string;
  onClick: (lan: string) => void;
}

export const LangMenuItem = ({ label, code, flag, flagSize = '32px', onClick }: LangMenuItemProps) => {
  return (
    <Menu.Item>
      {/** ADK: Retain this a button tag; its a rudimentary container in this case. */}
      {({ active }) => (
        <button onClick={() => onClick(code)} className="btn btn-ghost">
          <div className="flex items-center gap-x-2">
            <LangFlag flag={flag} flagSize={flagSize} />
            <span>{label}</span>
          </div>
        </button>
      )}
    </Menu.Item>
  );
};
