import { BigNumber } from 'ethers';
import { Shape, SYMBOL_ICONS } from 'lib/constants';
import { SymbolId } from 'lib/types';
import { useStores } from 'providers/StoreProvider';
import { useCallback, useEffect, useState } from 'react';
import RootStore from 'stores/RootStore';
import { Avatar } from '.';

const RecentSymbolList = () => {
  const { chainStore }: RootStore = useStores();

  const [shapeList, setShapeList] = useState<number[]>([]);
  const symbols = ['circle', 'circle', 'triangle', 'star', 'umbrella'];

  const getRecentShapeList = useCallback(async () => {
    const outcomeList: number[] = [];
    const gameId = await chainStore.getCurrentGameId();
    if (gameId) {
      const outcome = await chainStore.getGameOutcome(gameId);
      if (outcome !== null) {
        outcomeList.push(outcome);
      }
      const promises: Promise<number | null>[] = [];
      for (let i = 1; i < 9; i++) {
        const oldId = gameId.sub(BigNumber.from(i));
        if (oldId && oldId.toNumber() >= 0) {
          promises.push(chainStore.getGameOutcome(oldId));
        } else {
          break;
        }
      }

      if (promises.length > 0) {
        const outcomes = await Promise.all(promises);
        outcomes.forEach((outcome) => {
          console.log('out=', outcome);
          if (outcome !== null) {
            outcomeList.push(outcome);
          }
        });
      }

      console.log('outcomeList=', outcomeList);
      setShapeList(() => outcomeList);
    }
  }, [chainStore]);

  useEffect(() => {
    getRecentShapeList();
    chainStore.contract.on('OutcomeSet', async (requestId: any, gameId: any, outCome: Shape) => {
      console.log('OutcomeSet', requestId, gameId, outCome);
      getRecentShapeList();
    });
  }, [chainStore.contract, getRecentShapeList]);

  return (
    <div className="flex justify-center gap-3 mb-8 min-h-8">
      {shapeList.map((choice, index) => (
        <Avatar
          src={SYMBOL_ICONS[symbols[choice] as SymbolId].icon}
          symbol={SYMBOL_ICONS[symbols[choice] as SymbolId].name as SymbolId}
          key={index}
        />
      ))}
    </div>
  );
};

export default RecentSymbolList;
