import { BigNumber } from 'ethers';
import { BET_TIME_SECONDS, Shape, SYMBOL_ICONS } from 'lib/constants';
import RouletteContract from 'lib/utils/Contract';
import { action, computed, makeObservable, observable } from 'mobx';
import RootStore from './RootStore';

export class ChainStore {
  rootStore: RootStore;
  currentBetPrice: number = 0.035;
  betShape: Shape | undefined = undefined;
  wheelInterval: number = BET_TIME_SECONDS;
  currentGameId: any = null;
  isPlayerAlreadyBet: boolean = false;
  contract: RouletteContract = new RouletteContract();
  placeBetHashId: string = '';
  claimWinningHashId: string = '';
  betResult: any = null;
  mostRecentWheelSpinTime: any = null;
  passedTime: number = 0;
  bets: any = null;
  hasClaimWinnings: boolean = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.bindContractEventListeners();
    makeObservable(this, {
      //observables
      currentBetPrice: observable,
      betShape: observable,
      wheelInterval: observable,
      currentGameId: observable,
      isPlayerAlreadyBet: observable,
      hasClaimWinnings: observable,
      placeBetHashId: observable,
      claimWinningHashId: observable,
      passedTime: observable,
      withInTimeRange: computed,
      remainTime: computed,
      setCurrentBetPrice: action,
      getIsPlayerAlreadyBet: action,
    });
  }

  bindContractEventListeners() {
    /* 
    this.contract.on('WheelSpun', (args: any) => {});
    this.contract.on('OutcomeSet', (args: any) => {});
    */
  }

  setCurrentBetPrice(price: number) {
    this.currentBetPrice = price;
  }

  initStatusAfterBet() {
    this.betShape = undefined;
    this.isPlayerAlreadyBet = false;
    /* get new game id after bet result */
    this.getCurrentGameId();
  }

  async loadInitInfos(address: any) {
    await this.getIsPlayerAlreadyBet(address);
    await this.getWheelInterval();
    await this.getMostRecentWheelSpinTime();
    await this.getBets();
    await this.getGamesPlayedToClaim(address);
  }

  get betShapeMultiplier() {
    return Object.values(SYMBOL_ICONS)?.find((elm) => elm.value === this.betShape)?.multiplier;
  }

  get betShapeMultilierNumber() {
    return Object.values(SYMBOL_ICONS)?.find((elm) => elm.value === this.betShape)?.multiplierValue;
  }

  async getCurrentGameId() {
    this.currentGameId = await this.contract.getCurrentGameId();
    return this.currentGameId;
  }

  async getWheelInterval() {
    this.wheelInterval = await this.contract.getWheelTime();
  }

  async getMostRecentWheelSpinTime() {
    this.mostRecentWheelSpinTime = await this.contract.getMostRecentWheelSpinTime();
    if (this.mostRecentWheelSpinTime) this.passedTime = Math.floor(Date.now() / 1000) - this.mostRecentWheelSpinTime;
  }

  async getBets() {
    if (this.currentGameId) this.bets = await this.contract.getGameBets(this.currentGameId);
  }

  async getGamesPlayedToClaim(address: any) {
    const claimIds = await this.contract.getGamesPlayedToClaim(address);
    if (claimIds?.length) {
      const hasUnclaimedGameIds = await Promise.all(
        claimIds?.map(async (claimId: any) => {
          return await this.contract.getHasPlayerWon(address, claimId);
        })
      );
      this.hasClaimWinnings = hasUnclaimedGameIds?.find((id) => !!id) ? true : false;
    } else {
      this.hasClaimWinnings = false;
    }
  }

  async getIsPlayerAlreadyBet(address: any) {
    if (!this.currentGameId) await this.getCurrentGameId();
    this.isPlayerAlreadyBet = await this.contract.getHasPlayerBet(this.currentGameId, address);
    return this.isPlayerAlreadyBet;
  }

  async placeBet(shape: Shape) {
    this.betShape = shape;
    const result = await this.contract.placeBet(this.betShape, this.currentBetPrice);
    this.placeBetHashId = result ? result.hash : '';
    this.isPlayerAlreadyBet = true;
  }

  async claimWining(gameId: BigNumber) {
    return await this.contract.claimWining(gameId);
  }

  async claimAllWinnings() {
    const result = await this.contract.claimAllWinnings();
    if (result) {
      this.hasClaimWinnings = false;
      this.claimWinningHashId = result.hash ? result.hash : '';
    }
    return result;
  }

  setBetResult(result: any) {
    if (result) {
      this.betResult = result;
      this.isPlayerAlreadyBet = false;
    }
  }

  async getGameOutcome(gameId: BigNumber) {
    return await this.contract.getGameOutcome(gameId);
  }

  async getGameBets() {
    if (!this.currentGameId) await this.getCurrentGameId();
    return await this.contract.getGameBets(this.currentGameId);
  }

  get withInTimeRange() {
    return !!(this.passedTime && this.passedTime < this.wheelInterval);
  }

  get remainTime() {
    return this.wheelInterval - this.passedTime;
  }
}

export default ChainStore;
