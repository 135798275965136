import { useWeb3React } from '@web3-react/core';
import { BetButton, BetToastMessage } from 'components/elements';
import { BigNumber } from 'ethers';
import { Shape, SYMBOL_ICONS } from 'lib/constants';
import { SymbolId } from 'lib/types';
import { useStores } from 'providers/StoreProvider';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import RootStore from 'stores/RootStore';
import { BetStructOutput } from 'typechain/Roulette';
import * as BetColumn from '../specs/betColumn';

export const PlaceABetColumns = () => {
  const { chainStore, accountStore }: RootStore = useStores();

  const symbols = Object.keys(SYMBOL_ICONS);
  const { t } = useTranslation();
  const { account, library, chainId } = useWeb3React();
  const [betAcceptedStatus, setBetAcceptedStatus] = useState('');

  const placeBet = async (symbol: Shape) => {
    setBetAcceptedStatus('');
    if (!account) {
      toast.error(t('message.connect_wallet'));
      return;
    }
    if (chainStore.isPlayerAlreadyBet) {
      toast.error(t('message.already_bet'));
      return;
    }
    try {
      await chainStore.placeBet(symbol);
      setBetAcceptedStatus('success');
    } catch (err) {
      setBetAcceptedStatus('failure');
    }
  };

  useEffect(() => {
    if (chainStore.placeBetHashId) {
      chainStore.contract?.ethProvider?.waitForTransaction(chainStore.placeBetHashId).then((transaction: any) => {
        console.log('Bet Transaction', transaction);
        accountStore.getBalance(account, library, chainId);
      });
    }
  }, [account, accountStore, chainId, chainStore.placeBetHashId, library, chainStore.contract]);

  useEffect(() => {
    chainStore.contract.on('BetClaimed', () => {
      accountStore.getBalance(account, library, chainId);
      chainStore.getGamesPlayedToClaim(account);
    });
    chainStore.contract.on('ClaimedAll', () => {
      accountStore.getBalance(account, library, chainId);
      chainStore.getGamesPlayedToClaim(account);
    });
  }, [account, accountStore, chainId, library, chainStore.contract, chainStore]);

  const [bets, setBets] = useState<BetStructOutput[]>([]);

  const getBets = useCallback(async () => {
    const recentBets = await chainStore.getGameBets();
    setBets(recentBets ? recentBets : []);
  }, [chainStore]);

  const getBetsOfShape = (shape: Shape) => bets.filter((bet) => bet.choice === shape);

  useEffect(() => {
    getBets();
    chainStore.contract.on('BetPlaced', (gameId: any, player: string, bnbAmount: BigNumber, shape: Shape) => {
      getBets();
    });
  }, [chainStore.contract, getBets]);

  return (
    <div className="bg-footer relative flex justify-center pb-10 lg:-mt-20 z-0 p-4 h-full">
      <div className="lg:-mt-10 max-w-[1164px]">
        <div className="grid grid-cols-4 justify-items-center gap-4">
          {symbols.map((symbol, index) => (
            <div className="flex flex-col w-full" key={index}>
              <BetButton
                src={SYMBOL_ICONS[symbol as SymbolId].icon}
                multiplier={SYMBOL_ICONS[symbol as SymbolId].multiplier}
                onClick={() => {
                  placeBet(SYMBOL_ICONS[symbol as SymbolId].value);
                }}
              />
            </div>
          ))}
        </div>
        <div className="grid grid-cols-2 lg:grid-cols-4 justify-items-center gap-4">
          {symbols.map((symbol, index) => (
            <div className="flex flex-col w-full" key={index}>
              <BetColumn.Column bets={getBetsOfShape(SYMBOL_ICONS[symbol as SymbolId].value)} />
            </div>
          ))}
        </div>
      </div>
      <BetToastMessage
        status={betAcceptedStatus}
        betType={chainStore.betShapeMultiplier}
        betAmount={chainStore.currentBetPrice.toString()}
        hashId={chainStore.placeBetHashId}
      ></BetToastMessage>
    </div>
  );
};
