/**
 * Sets up the a standard layout format; other layouts can use composition
 * to ensure consistency.
 * @param className applies to the `<main>` component.
 *
 */
export const Layout: React.FC<{ navbar?: JSX.Element }> = ({ children, navbar }) => {
  return (
    <>
      {navbar}
      {children}
    </>
  );
};
