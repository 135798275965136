import { TableItem } from '.';

const TableBody = ({ players }: { players: string[] }) => {
  return (
    <div key={players.length} className="flex flex-col gap-2 min-h-[200px] ">
      {players.map((player, index) => (
        <TableItem username={player} key={index} />
      ))}
    </div>
  );
};

export default TableBody;
