import { Dialog, Transition } from '@headlessui/react';
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { Fragment, useEffect } from 'react';
import { injected, useIsWalletConnectedBsc, walletConnect } from '../../lib/utils/helpers';
import ModalBody from './ModalBody';

const SelectWalletModal = ({
  setModalView,
  modalShow,
}: {
  setModalView: (isShow: boolean) => void;
  modalShow: boolean;
}) => {
  const { activate, library } = useWeb3React();

  const isWalletConnectedBsc = useIsWalletConnectedBsc();
  useEffect(() => {
    if (isWalletConnectedBsc) {
      setModalView(false);
    }
  }, [isWalletConnectedBsc, setModalView]);

  // const checkWeb3Wallet = () => {
  //   if (!window.ethereum) {
  //     alert('Please install a web3 wallet.');
  //     console.error('No MetaMask');
  //     return false;
  //   }
  //   return true;
  // };
  const metaMaskConnect = async () => {
    //if (!checkWeb3Wallet()) return;
    try {
      await activate(injected, undefined, true);
    } catch (e) {
      if (e instanceof UnsupportedChainIdError) {
        alert('Please add the Binance Smart Chain network to your metamask wallet.');
        console.error('UnsupportedChainIdError');
      } else {
        console.error('Wallet Connect Error', e);
      }
    }
  };

  const switchNetwork = async () => {
    await library.provider.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: '0x38' }], // BSC mainnet ChainID 56
    });
  };

  function closeModal() {
    setModalView(false);
  }

  const resetWalletConnector = (connector: WalletConnectConnector) => {
    if (connector && connector instanceof WalletConnectConnector) {
      connector.walletConnectProvider = undefined;
    }
  };

  const walletConnector = async () => {
    try {
      await activate(walletConnect);
      resetWalletConnector(walletConnect);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <div
        onClick={() => {
          setModalView(false);
        }}
      >
        <Transition appear show={modalShow} as={Fragment}>
          <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={closeModal}>
            <div className="min-h-screen px-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0" />
              </Transition.Child>

              <ModalBody
                switchNetwork={switchNetwork}
                metaMaskConnect={metaMaskConnect}
                walletConnector={walletConnector}
                closeModal={closeModal}
              />
            </div>
          </Dialog>
        </Transition>
      </div>
    </>
  );
};

export default SelectWalletModal;
