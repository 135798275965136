import { ButtonProps } from 'lib/types';
import { useTranslation } from 'react-i18next';

const WalletButton = ({ text, onClick, className }: ButtonProps) => {
  const { t } = useTranslation();
  return (
    <button className={`btn btn-ghost connect-wallet text-black ${className}`} onClick={onClick}>
      <span>{t('header.connect')}</span>
    </button>
  );
};

export default WalletButton;
