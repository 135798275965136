import { useWeb3React } from '@web3-react/core';
import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { useCallback, useRef } from 'react';
//import { chain } from "cypress/types/lodash";

export const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42, 56, 97],
});
export const walletConnect = new WalletConnectConnector({
  rpc: {
    56: 'https://bsc-dataseed.binance.org/',
    97: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
  },
});

export const useIsWalletConnectedBsc = () => {
  const { chainId, account } = useWeb3React();
  if (chainId && account) {
    return account && (chainId.toString() === '56' || chainId.toString() === '97');
  } else {
    return false;
  }
};

export const useIsWalletConnected = () => {
  const { chainId, account } = useWeb3React();
  return !!(account && chainId);
};

export const useStaticCallback = (callback: any, dependencies: any[]) => {
  const ref = useRef<any>(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const _callback = useCallback(callback, dependencies);
  ref.current = _callback;

  return useCallback(
    (...args) => {
      return ref.current(...args);
    },
    [ref]
  );
};

export const formatAccount = (account: string) => {
  return account.slice(0, 5) + '...' + account.slice(-5);
};
