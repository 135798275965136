export const CC_LEN = 2;

/**
 * `flag` can be used to distinguish between different countries; useful if using dual language code (e.g., es-SP)
 */
export const countryInfo = [
  { active: false, enLabel: 'Czech', lang: 'ch', natLabel: 'Český', flag: 'cz' },
  { active: false, enLabel: 'French', lang: 'fr', natLabel: 'Français', flag: 'fr' },
  { active: false, enLabel: 'German', lang: 'de', natLabel: 'Deutsch', flag: 'de' },
  { active: false, enLabel: 'Italian', lang: 'it', natLabel: 'Italiano', flag: 'it' },
  { active: false, enLabel: 'Japanese', lang: 'ja', natLabel: '日本', flag: 'jp' },
  { active: false, enLabel: 'Portuguese', lang: 'pt', natLabel: 'Português', flag: 'pt' },
  { active: false, enLabel: 'Spanish', lang: 'es', natLabel: 'Español', flag: 'es' },
  { active: false, enLabel: 'Thai', lang: 'th', natLabel: 'ไทย', flag: 'th' },
  { active: true, enLabel: 'Chinese', lang: 'zh', natLabel: '中国人', flag: 'cn' },
  { active: true, enLabel: 'English', lang: 'en', natLabel: 'English', flag: 'us' },
  { active: true, enLabel: 'Korean', lang: 'ko', natLabel: '한국인', flag: 'kr' },
  { active: true, enLabel: 'Russian', lang: 'ru', natLabel: 'Pусский', flag: 'ru' },
];
