import { Web3Provider } from '@ethersproject/providers';
import { Web3ReactProvider } from '@web3-react/core';
import MetamaskProvider from 'lib/utils/MetaMaskProvider';
import { StoreProvider } from 'providers/StoreProvider';
import React from 'react';
import ReactDOM from 'react-dom';
import RootStore from 'stores/RootStore';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './style.css';

function getLibrary(provider: any, _connector: any) {
  return new Web3Provider(provider);
}
const store = new RootStore();

ReactDOM.render(
  <Web3ReactProvider getLibrary={getLibrary}>
    <MetamaskProvider>
      <StoreProvider store={store}>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </StoreProvider>
    </MetamaskProvider>
  </Web3ReactProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
