import { ButtonProps } from 'lib/types';
import { Avatar } from '..';

const HeaderButton = ({
  src,
  text,
  symbol,
  alt,
  usdBalance,
  size = 'h-sm',
  className,
}: {
  symbol?: 'BNB' | 'SQM';
  alt: 'bnb' | 'sqm' | 'metamask address';
  size?: 'h-xs' | 'h-sm';
  className?: string;
} & ButtonProps) => {
  return (
    <button className={`btn btn-ghost gap-2 bg-transparent ${className}`}>
      {src && <Avatar src={src} alt={alt} size={size} />}
      <span>
        {text}&#32;{symbol ? symbol : ''}
      </span>

      {symbol === 'SQM' && <span className="text-nav-edge">${usdBalance}</span>}
    </button>
  );
};

export default HeaderButton;
