export const TRANSLATIONS_KO = {
   flag: 'kr',
   header: {
    connect: "지갑을 연결"
   },
   footer: {
       placeABet: "베팅하기",
       total: "총 베팅"
   },
   body: {
       wheel: {
           startIn: "에서 시작",
           choose: "배팅 금액 선택"
       }
   }
};
