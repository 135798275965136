import { Avatar } from 'components/elements';
import { useTranslation } from 'react-i18next';

const TableHeader = ({ betTotal }: { betTotal: string }) => {
  const { t } = useTranslation();
  return (
    <div className="flex justify-between items-center mb-4">
      <div className="text-footer bg-footer-table-surface">
        <span className="text-sm text-black tracking-tight">{t('footer.total')}</span>
      </div>
      <div className="text-footer bg-footer-table-surface">
        <div className="flex gap-2 justify-center items-center">
          <Avatar src="/images/icons/footer_bnb.svg" size="h-xs" alt="bnb icon" />
          <span className="text-xl font-bold">{betTotal}</span>
        </div>
      </div>
    </div>
  );
};

export default TableHeader;
