import { ethers } from 'ethers';
import { BET_TIME_SECONDS, Shape, SPUN_GAME_IDS } from 'lib/constants';
import {
  Roulette,
  Roulette__factory,
  VRFCoordinatorV2Interface,
  VRFCoordinatorV2Interface__factory,
} from '../../typechain';
const EventEmitter = require('events');

/* Will be moved to .env */
export const mainnet: boolean = true;

const proxyContractAddress = mainnet
  ? '0x5befe47e900c7daffc56bbebb937b4deb99d6b1e'
  : '0xd174396293FeA281877A019c810D2076490ae004';
const coordinatorContractAddress = mainnet
  ? '0xc587d9053cd1118f25F645F9E08BB98c9712A4EE'
  : '0x6a2aad07396b36fe02a22b33cf443582f682c82f';

interface Ethereum {
  // on<K extends EventKeys>(event: K, eventHandler: EventHandler<K>): void; // ADK: keep for later.
}
declare global {
  interface Window {
    ethereum: Ethereum;
  }
}

class RouletteContract extends EventEmitter {
  ethProvider: any = null;
  walletAddress: string = '';
  gameContract: Roulette | undefined = undefined;
  coordinatorContract: VRFCoordinatorV2Interface | undefined = undefined;

  public async setEthProvider() {
    if (window.ethereum) {
      this.ethProvider = new ethers.providers.Web3Provider(window.ethereum);
      await this.ethProvider.send('eth_requestAccounts', []);
      if (this.signer) this.walletAddress = await this.signer.getAddress();
    }
  }

  public get signer() {
    if (this.ethProvider) return this.ethProvider.getSigner();
    return null;
  }

  public async getWheelTime() {
    const wheelTime = await this.gameContract?.wheelInterval();
    return wheelTime ? wheelTime.toNumber() : BET_TIME_SECONDS;
  }

  public async connectGameContract() {
    if (this.gameContract) return;
    if (!this.ethProvider) await this.setEthProvider();
    if (this.signer && this.ethProvider) {
      try {
        this.gameContract = Roulette__factory.connect(proxyContractAddress, this.signer);
        this.coordinatorContract = VRFCoordinatorV2Interface__factory.connect(coordinatorContractAddress, this.signer);
        this.bindEventListeners();
      } catch (err) {}
    }
  }

  bindEventListeners() {
    this.gameContract?.on('WheelSpun', (requestId, gameId) => {
      this.emit('WheelSpun', requestId, gameId);
    });
    this.gameContract?.on('OutcomeSet', async (requestId, gameId, outCome) => {
      const gameIds = localStorage.getItem(SPUN_GAME_IDS);
      if (!(gameIds && gameIds.split(',')?.includes(gameId.toString()))) {
        this.emit('OutcomeSet', requestId, gameId, outCome);
      }
    });
    this.gameContract?.on('BetClaimed', (gameId, player, amountWonInBnb, bet) => {
      this.emit('BetClaimed', gameId, player, amountWonInBnb, bet);
    });
    this.gameContract?.on('ClaimedAll', (args) => {
      this.emit('ClaimedAll', args);
    });
    this.gameContract?.on('BetPlaced', (args) => {
      this.emit('BetPlaced', args);
    });
    this.coordinatorContract?.on('RandomWordsFulfilled', (args) => {
      console.log('Random Words Fulfilled');
      this.emit('RandomWordsFulfilled', args);
    });
  }

  public async setWheelInterval(seconds: number) {
    await this.connectGameContract();
    if (this.gameContract) {
      const result = await this.gameContract.setWheelInterval(seconds);
      return result;
    }
    return null;
  }

  public async getHasPlayerBet(gameId: any, playerAddress: any) {
    await this.connectGameContract();
    if (this.gameContract) {
      const result = await this.gameContract.getHasPlayerBet(gameId, playerAddress);
      return result;
    }
    return false;
  }

  public async getMostRecentWheelSpinTime() {
    await this.connectGameContract();
    if (this.gameContract) {
      const result = await this.gameContract.getMostRecentWheelSpinTime();
      return result.toNumber();
    }
    return null;
  }

  public async placeBet(choice: Shape, amount: any) {
    await this.connectGameContract();
    if (this.gameContract) {
      const result = await this.gameContract.placeBet(choice, { value: ethers.utils.parseEther(String(amount)) });
      return result;
    }
    return null;
  }

  public async claimWining(gameId: any) {
    await this.connectGameContract();
    if (this.gameContract) {
      const result = await this.gameContract.claimWinning(gameId);
      return result;
    }
    return null;
  }

  public async claimAllWinnings() {
    await this.connectGameContract();
    if (this.gameContract) {
      const result = await this.gameContract.claimAllWinnings();
      return result;
    }
    return null;
  }

  public async spinWheel() {
    await this.connectGameContract();
    if (this.gameContract) {
      const result = await this.gameContract.spinWheel();
      return result;
    }
    return null;
  }

  public async getCurrentGameId() {
    await this.connectGameContract();
    if (this.gameContract) {
      const result = await this.gameContract.getCurrentGameId();
      return result;
    }
    return null;
  }

  public async getPlayerBet(adress: any, gameId: any) {
    await this.connectGameContract();
    if (this.gameContract) {
      const result = await this.gameContract.getPlayerBet(adress, gameId);
      return result;
    }
    return null;
  }

  public async getGamesPlayedToClaim(adress: any) {
    await this.connectGameContract();
    if (this.gameContract) {
      const result = await this.gameContract.getGamesPlayedToClaim(adress);
      return result;
    }
    return null;
  }

  public async getHasPlayerWon(address: any, gameId: any) {
    await this.connectGameContract();
    if (this.gameContract) {
      return await this.gameContract.getHasPlayerWon(address, gameId);
    }
    return false;
  }

  public async getGameBets(gameId: any) {
    await this.connectGameContract();
    if (this.gameContract) {
      const contract = this.gameContract;
      const players = await contract.getPlayers(gameId);
      // for each player call getPlayerBet with gameId
      return await Promise.all(
        players.map(async (player: string) => {
          const bet = await contract.getPlayerBet(player, gameId);
          return bet;
        })
      );
    }
    return null;
  }

  public async getGameRequestId(gameId: any) {
    await this.connectGameContract();
    if (this.gameContract) {
      const result = await this.gameContract.getGameRequestId(gameId);
      return result;
    }
    return null;
  }

  public async getGameTimestamp(gameId: any) {
    await this.connectGameContract();
    if (this.gameContract) {
      const result = await this.gameContract.getGameTimestamp(gameId);
      return result;
    }
    return null;
  }

  public async getGameOutcome(gameId: any) {
    await this.connectGameContract();
    if (this.gameContract) {
      const result = await this.gameContract.getGameOutcome(gameId);
      return result;
    }
    return null;
  }

  public async getGameStatus(gameId: any) {
    await this.connectGameContract();
    if (this.gameContract) {
      const result = await this.gameContract.getGameStatus(gameId);
      return result;
    }
    return null;
  }
}

export default RouletteContract;
