import { Menu, Transition } from '@headlessui/react';
import { Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { CC_LEN, countryInfo } from './countryInfo';
import { LangFlag } from './LangFlag';
import { LangMenuItem } from './LangMenuItem';
import { stringOfLength } from './stringOfLength';

export const LanguagePicker = () => {
  const { t, i18n } = useTranslation();
  const handleChangeLanguage = useCallback(
    (lan) => {
      i18n.changeLanguage(lan);
    },
    [i18n]
  );
  return (
    <Menu as="div" className="relative z-50  text-left hidden lg:inline-block">
      <div className="flex justify-center">
        <Menu.Button>
          <LangFlag flag={t('flag')} />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="divide-hr ring-nav bg-nav-surface absolute right-0 mt-2 w-32 origin-top-right divide-y rounded-md shadow-lg ring-1 ring-opacity-5 focus:outline-none">
          <div className="px-1 py-1">
            {countryInfo
              .filter((country) => country.active)
              .map((country, index) => (
                <LangMenuItem
                  onClick={handleChangeLanguage}
                  key={index}
                  label={country.natLabel}
                  code={stringOfLength(country.lang, CC_LEN, CC_LEN)}
                  flag={stringOfLength(country.flag, CC_LEN, CC_LEN)}
                />
              ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
