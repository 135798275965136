import { RecentSymbolList, WheelSpinner } from 'components/elements';
import { PlaceABetColumns } from 'components/elements/PlaceABetColumns';
import { WHEEL_ITEMS } from 'lib/constants';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Header, Layout } from './components/layouts';
import './translations/i18n';

function App() {
  return (
    <Layout navbar={<Header />}>
      <main className="absolute bg-main bg-cover h-[700px]  w-full lg:h-[800px] top-0 pt-28">
        <div className="relative h-full overflow-hidden">
          <RecentSymbolList />
          <WheelSpinner items={WHEEL_ITEMS} />
        </div>
        <PlaceABetColumns />
      </main>
      <ToastContainer />
    </Layout>
  );
}

export default App;
