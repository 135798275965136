import { useTranslation } from 'react-i18next';

const small = '/images/SQMLogo_sm.png';
const large = '/images/SQMLogo.png ';

const Logo = () => {
  const { t } = useTranslation();
  return (
    <picture>
      <source srcSet={large} media="(min-width: 1024px)" />
      <img src={small} className="w-3/4 lg:w-48 lg:max-w-xl" alt={t('alt.logo')} />
    </picture>
  );
};

export default Logo;
