/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Signer, utils, Contract, ContractFactory, Overrides } from 'ethers';
import { Provider, TransactionRequest } from '@ethersproject/providers';
import type { MockVRF, MockVRFInterface } from '../MockVRF';

const _abi = [
  {
    inputs: [
      {
        internalType: 'uint64',
        name: 'subId',
        type: 'uint64',
      },
    ],
    name: 'acceptSubscriptionOwnerTransfer',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint64',
        name: 'subId',
        type: 'uint64',
      },
      {
        internalType: 'address',
        name: 'consumer',
        type: 'address',
      },
    ],
    name: 'addConsumer',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint64',
        name: 'subId',
        type: 'uint64',
      },
      {
        internalType: 'address',
        name: 'to',
        type: 'address',
      },
    ],
    name: 'cancelSubscription',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'createSubscription',
    outputs: [
      {
        internalType: 'uint64',
        name: 'subId',
        type: 'uint64',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getRequestConfig',
    outputs: [
      {
        internalType: 'uint16',
        name: '',
        type: 'uint16',
      },
      {
        internalType: 'uint32',
        name: '',
        type: 'uint32',
      },
      {
        internalType: 'bytes32[]',
        name: '',
        type: 'bytes32[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint64',
        name: 'subId',
        type: 'uint64',
      },
    ],
    name: 'getSubscription',
    outputs: [
      {
        internalType: 'uint96',
        name: 'balance',
        type: 'uint96',
      },
      {
        internalType: 'uint64',
        name: 'reqCount',
        type: 'uint64',
      },
      {
        internalType: 'address',
        name: 'owner',
        type: 'address',
      },
      {
        internalType: 'address[]',
        name: 'consumers',
        type: 'address[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint64',
        name: 'subId',
        type: 'uint64',
      },
      {
        internalType: 'address',
        name: 'consumer',
        type: 'address',
      },
    ],
    name: 'removeConsumer',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: 'keyHash',
        type: 'bytes32',
      },
      {
        internalType: 'uint64',
        name: 'subId',
        type: 'uint64',
      },
      {
        internalType: 'uint16',
        name: 'minimumRequestConfirmations',
        type: 'uint16',
      },
      {
        internalType: 'uint32',
        name: 'callbackGasLimit',
        type: 'uint32',
      },
      {
        internalType: 'uint32',
        name: 'numWords',
        type: 'uint32',
      },
    ],
    name: 'requestRandomWords',
    outputs: [
      {
        internalType: 'uint256',
        name: 'requestId',
        type: 'uint256',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint64',
        name: 'subId',
        type: 'uint64',
      },
      {
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'requestSubscriptionOwnerTransfer',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
];

const _bytecode =
  '0x608060405234801561001057600080fd5b50610362806100206000396000f3fe608060405234801561001057600080fd5b50600436106100925760003560e01c8063823597401161006657806382359740146100f95780639f87fad7146100be578063a21a23e41461010a578063a47c769614610119578063d7ae1d30146100be57600080fd5b80620122911461009757806304c357cb146100be5780635d3b1d30146100d25780637341c10c146100be575b600080fd5b604080516000815260208101918290526100b5916001918291610150565b60405180910390f35b6100d06100cc3660046101cc565b5050565b005b6100eb6100e0366004610223565b600095945050505050565b6040519081526020016100b5565b6100d061010736600461028a565b50565b604051600081526020016100b5565b61014061012736600461028a565b5060408051600080825260208201909252909182918291565b6040516100b594939291906102ac565b60006060820161ffff86168352602063ffffffff86168185015260606040850152818551808452608086019150828701935060005b818110156101a157845183529383019391830191600101610185565b509098975050505050505050565b803567ffffffffffffffff811681146101c757600080fd5b919050565b600080604083850312156101df57600080fd5b6101e8836101af565b915060208301356001600160a01b038116811461020457600080fd5b809150509250929050565b803563ffffffff811681146101c757600080fd5b600080600080600060a0868803121561023b57600080fd5b8535945061024b602087016101af565b9350604086013561ffff8116811461026257600080fd5b92506102706060870161020f565b915061027e6080870161020f565b90509295509295909350565b60006020828403121561029c57600080fd5b6102a5826101af565b9392505050565b6000608082016bffffffffffffffffffffffff87168352602067ffffffffffffffff87168185015260018060a01b0380871660408601526080606086015282865180855260a087019150838801945060005b8181101561031c5785518416835294840194918401916001016102fe565b50909a995050505050505050505056fea26469706673582212203b8e5d4f7f4a36002d2c93f27c7bf42e6898c92e7893cc82baa3278f1222dd2964736f6c63430008090033';

type MockVRFConstructorParams = [signer?: Signer] | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (xs: MockVRFConstructorParams): xs is ConstructorParameters<typeof ContractFactory> =>
  xs.length > 1;

export class MockVRF__factory extends ContractFactory {
  constructor(...args: MockVRFConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
  }

  deploy(overrides?: Overrides & { from?: string | Promise<string> }): Promise<MockVRF> {
    return super.deploy(overrides || {}) as Promise<MockVRF>;
  }
  getDeployTransaction(overrides?: Overrides & { from?: string | Promise<string> }): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  attach(address: string): MockVRF {
    return super.attach(address) as MockVRF;
  }
  connect(signer: Signer): MockVRF__factory {
    return super.connect(signer) as MockVRF__factory;
  }
  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): MockVRFInterface {
    return new utils.Interface(_abi) as MockVRFInterface;
  }
  static connect(address: string, signerOrProvider: Signer | Provider): MockVRF {
    return new Contract(address, _abi, signerOrProvider) as MockVRF;
  }
}
