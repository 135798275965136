import { useStores } from 'providers/StoreProvider';
import { useEffect, useState } from 'react';
import RootStore from 'stores/RootStore';
import { useInterval } from 'usehooks-ts';

export const CountdownTimer = ({ seconds, start }: { seconds: number; start: boolean }) => {
  const [countdownSeconds, setCountdownSeconds] = useState<number>(seconds);
  const { chainStore }: RootStore = useStores();

  const getCountDownStr = () => {
    const mins = Math.floor(countdownSeconds / 60)
      .toFixed()
      .toString();
    const secs = `${countdownSeconds % 60 < 10 ? '0' : ''}${(countdownSeconds % 60).toString()}`;
    return `${mins}:${secs}`;
  };

  useInterval(
    () => {
      if (countdownSeconds === 1) {
        chainStore.getMostRecentWheelSpinTime();
      }
      setCountdownSeconds(countdownSeconds - 1);
    },
    // Delay in milliseconds or null to stop it
    start && countdownSeconds > 0 ? 1000 : null
  );

  useEffect(() => {
    setCountdownSeconds(seconds);
  }, [seconds]);

  return <div className="text-6xl text-button-surface font-gos">{getCountDownStr()}</div>;
};
