import { formatAccount } from 'lib/utils/helpers';
import { useTranslation } from 'react-i18next';
import { Avatar } from '../../elements';

const TableItem = ({ username, userImage = '' }: { username: string; userImage?: string }) => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center space-x-3 text-footer">
      <div className="btn btn-circle btn-outline noHover border-bet-amount-surface">
        <Avatar src={userImage} alt={t('alt.username')} username={username} />
      </div>
      <div>
        <div className="font-bold">{formatAccount(username)}</div>
      </div>
    </div>
  );
};

export default TableItem;
