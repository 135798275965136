import { useTranslation } from 'react-i18next';
import Identicon from 'react-identicons';
import { SymbolId } from '../../lib/types';

/**
 * @param src url of image to be shown.
 * @param alt alternative text; will not be translated, unless provided as such.
 * @param symbol symbol name; if present, it will be translated and override the alt text.
 */
export type AvatarProps =
  | {
      src: string;
      size?: 'h-xs' | 'h-sm' | 'h-md' | 'h-lg';
      alt: string;
      symbol?: never;
      username?: string;
    }
  | {
      src: string;
      size?: 'h-xs' | 'h-sm' | 'h-md' | 'h-lg';
      alt?: never;
      symbol: SymbolId;
      username?: string;
    };

const Avatar = ({ src, alt, symbol, size = 'h-sm', username }: AvatarProps) => {
  const { t } = useTranslation();

  return username ? (
    <Identicon string={username} size={25} />
  ) : (
    <img className={`${size} w-auto`} src={src} alt={!alt && symbol ? t(`symbol.${symbol}`) : alt} />
  );
};

export default Avatar;
