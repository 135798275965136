import { ButtonProps } from 'lib/types';
import { useStores } from 'providers/StoreProvider';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import RootStore from 'stores/RootStore';
import Avatar from '../Avatar';

const ClaimAllButton = ({ className }: ButtonProps) => {
  const { t } = useTranslation();
  const { chainStore }: RootStore = useStores();

  const claimAll = async () => {
    try {
      const result = await chainStore.claimAllWinnings();
      if (result) {
        toast.success(t('message.claimWinningAllSuccess'));
      } else {
        toast.error(t('message.claimWinningAllFailure'));
      }
    } catch (err) {
      toast.error(t('message.claimWinningAllFailure'));
    }
  };

  return (
    <button className={`btn btn-ghost claim-all text-black ml-6 cursor-pointer ${className || ''}`} onClick={claimAll}>
      <Avatar src={'/images/icons/group.png'} alt={t('header.claimAll')} size="h-xs" />
      <span className="ml-2">{t('header.claimAll')}</span>
    </button>
  );
};

export default ClaimAllButton;
