const BetAmountButton = ({
  value,
  onClick,
  isActive,
  className,
}: {
  value: number;
  onClick?: () => void;
  className?: string;
  isActive: boolean;
}) => {
  return (
    <button
      className={`btn text-bet-amount ${
        isActive ? 'bg-bet-amount-foil' : 'bg-bet-amount-surface'
      } rounded-3xl text-black hover:bg-bet-amount-foil`}
      onClick={onClick}
    >
      {value} BNB
    </button>
  );
};

export default BetAmountButton;
