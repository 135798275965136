/**
 * !NOTE! Store number based constants in numConstants.ts
 */
export const SPUN_GAME_IDS = 'spun_game_id';
export const CANONICAL_APP_NAME = 'Shape Picking Roulette';

export const BET_TIME_SECONDS = 600;
export const BET_AMOUNTS = [0.035, 0.1, 0.25, 0.5, 1];
export const SQM_ADDRESS: { [key: number]: string } = {
  56: '0x2766cc2537538ac68816b6b5a393fa978a4a8931',
  97: '0x141B4a4b7153E1C8a412915924abB96ABEC34BE7',
};
export const PANCAKE_ADDRESS: { [key: number]: string } = {
  56: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
  97: '0xD99D1c33F9fC3444f8101754aBC46c52416550D1',
};

export const BNB_TESTSCAN_URL = 'https://testnet.bscscan.com/tx/';
export const BNB_SCAN_URL = 'https://bscscan.com/tx/';

export const SQM_PANCAKE_RATE_URL = 'https://api.coingecko.com/api/v3/simple/price?ids=squid-moon&vs_currencies=usd';

export enum Shape {
  DEFAULT,
  CIRCLE,
  TRIANGLE,
  STAR,
  UMBRELLA,
}

export enum Status {
  PENDING,
  COMPLETE,
}

export const SYMBOL_ICONS = {
  circle: {
    name: 'circle',
    value: Shape.CIRCLE,
    icon: '/images/symbols/symbol_circle.png',
    multiplier: '1x',
    multiplierValue: 1,
  },
  triangle: {
    name: 'triangle',
    value: Shape.TRIANGLE,
    icon: '/images/symbols/symbol_triangle.png',
    multiplier: '2x',
    multiplierValue: 2,
  },
  star: {
    name: 'star',
    value: Shape.STAR,
    icon: '/images/symbols/symbol_star.png',
    multiplier: '3x',
    multiplierValue: 3,
  },
  umbrella: {
    name: 'umbrella',
    value: Shape.UMBRELLA,
    icon: '/images/symbols/symbol_umbrella.png',
    multiplier: '11x',
    multiplierValue: 11,
  },
};

export const WHEEL_ITEMS = [
  {
    id: 0,
    icon: '/images/symbols/wheel_star.png',
    value: Shape.STAR,
  },
  {
    id: 1,
    icon: '/images/symbols/wheel_circle.png',
    value: Shape.CIRCLE,
  },
  {
    id: 2,
    icon: '/images/symbols/wheel_triangle.png',
    value: Shape.TRIANGLE,
  },
  {
    id: 3,
    icon: '/images/symbols/wheel_umbrella.png',
    value: Shape.UMBRELLA,
  },
  {
    id: 4,
    icon: '/images/symbols/wheel_circle.png',
    value: Shape.CIRCLE,
  },
  {
    id: 5,
    icon: '/images/symbols/wheel_umbrella.png',
    value: Shape.UMBRELLA,
  },
  {
    id: 6,
    icon: '/images/symbols/wheel_triangle.png',
    value: Shape.TRIANGLE,
  },
  {
    id: 7,
    icon: '/images/symbols/wheel_circle.png',
    value: Shape.CIRCLE,
  },
  {
    id: 8,
    icon: '/images/symbols/wheel_star.png',
    value: Shape.STAR,
  },
  {
    id: 9,
    icon: '/images/symbols/wheel_circle.png',
    value: Shape.CIRCLE,
  },
  {
    id: 10,
    icon: '/images/symbols/wheel_triangle.png',
    value: Shape.TRIANGLE,
  },
  {
    id: 11,
    icon: '/images/symbols/wheel_circle.png',
    value: Shape.CIRCLE,
  },
];
