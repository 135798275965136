import { Dialog, Transition } from '@headlessui/react';
import { XCircleIcon } from '@heroicons/react/outline';
import { SQM_ADDRESS } from 'lib/constants';
import { mainnet } from 'lib/utils/Contract';
import { Fragment, useEffect, useState } from 'react';

const BetResultModal = ({
  setModalView,
  modalShow,
  amount,
  win,
}: {
  setModalView: (isShow: boolean) => void;
  modalShow: boolean;
  amount: string;
  win?: boolean;
}) => {
  const [sqmPrice, setSqmPrice] = useState('0');

  useEffect(() => {
    async function fetchSQM() {
      if (win) {
        setSqmPrice(await getSQMFromBNB(amount));
      }
    }
    fetchSQM();
  }, [amount, win]);

  function closeModal() {
    setModalView(false);
  }

  async function getSQMFromBNB(amount: string): Promise<string> {
    const URL = 'https://api.pancakeswap.info/api/v2/tokens/';
    const query = URL + SQM_ADDRESS[56];
    if (mainnet === false) {
      return 'only can get SQM price on mainnet';
    }
    const resp = await (await fetch(query)).json();
    return (Number(amount) / resp.data.price_BNB).toFixed(3);
  }

  return (
    <>
      <div
        onClick={() => {
          setModalView(false);
        }}
      >
        <Transition appear show={modalShow} as={Fragment}>
          <Dialog as="div" className="fixed inset-0 z-10" onClose={closeModal}>
            <div className="min-h-screen px-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0" />
              </Transition.Child>
              {/* This element is to trick the browser into centering the modal contents. */}
              <span className="inline-block h-screen align-middle" aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="you-win inline-block w-full max-w-md h-96 text-left align-middle transition-all transform shadow-xl rounded-2xl">
                  <label className="absolute right-2 top-2 cursor-pointer" onClick={closeModal}>
                    <XCircleIcon className="h-5 text-footer" />
                  </label>
                  {win ? (
                    <div className="align-center text-center pt-4">
                      <p className="win">Win</p>
                      <h1 className="winning-amount">+{amount} BNB</h1>
                      <h3 className="win">in SQM ({sqmPrice})</h3>
                      <p className="win">Congratulations for your win</p>
                    </div>
                  ) : (
                    <div className="align-center text-center pt-4">
                      <p className="win">Lose</p>
                      <h1 className="winning-amount">{amount} BNB</h1>
                      <p className="win">Better luck next time</p>
                    </div>
                  )}
                </div>
              </Transition.Child>{' '}
            </div>
          </Dialog>
        </Transition>
      </div>
    </>
  );
};

export default BetResultModal;
