import { Contract, utils } from 'ethers';
import { SQM_ADDRESS, SQM_PANCAKE_RATE_URL } from 'lib/constants';
import sqmAbi from 'lib/utils/sqm.json';
import { action, makeObservable, observable } from 'mobx';
import RootStore from './RootStore';

export class AccountStore {
  rootStore: RootStore;
  account: string = '';
  formattedAccount: string = '';
  eqxBalance: string = '0';
  sqmBalance: number = 0;
  sqmRate: any = null;
  sqmUsd: string = '0.00';

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      //observables
      account: observable,
      formattedAccount: observable,
      eqxBalance: observable,
      sqmBalance: observable,
      sqmRate: observable,
      sqmUsd: observable,
      getBalance: action,
    });
  }

  async setSqmRatePancake() {
    const result = await fetch(SQM_PANCAKE_RATE_URL);
    const json = await result.json();
    if (json) {
      const price = json['squid-moon']['usd'];
      this.sqmRate = price;
      this.setSqmUsd();
    }
  }

  async getBalance(account: any, library: any, chainId: any) {
    try {
      if (account && chainId) {
        const sqmContract = new Contract(SQM_ADDRESS[chainId], sqmAbi, library.getSigner());
        const sqmBln = await sqmContract.balanceOf(account);
        const sqmDecimal = await sqmContract.decimals();
        this.sqmBalance = sqmBln / 10 ** sqmDecimal;

        const balance = await library.getBalance(account);
        const formatedBalance = await utils.formatEther(balance).toString();
        this.eqxBalance = formatedBalance;
        this.setSqmUsd();
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  setSqmUsd() {
    if (this.sqmBalance && this.sqmRate) {
      this.sqmUsd = (this.sqmBalance * parseFloat(this.sqmRate)).toLocaleString('en-US', { maximumFractionDigits: 2 });
    }
  }

  setAccount(account: any) {
    this.account = account;
    this.rootStore.chainStore.loadInitInfos(account);
  }

  setFormattedAccount(formattedAccount: any) {
    this.formattedAccount = formattedAccount;
  }
}

export default AccountStore;
