const LeftDoors = () => {
  return (
    <div className="door justify-start">
      <img className="outer" src="/images/doors/UmbrellaDoor.svg" alt="Umbrella" />
      <img className="inner" src="/images/doors/StarDoor.svg" alt="Star" />
    </div>
  );
};

export default LeftDoors;
