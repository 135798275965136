const RightDoors = () => {
  return (
    <div className="door justify-end">
      <img className="inner" src="/images/doors/CircleDoor.svg" alt="Circle" />
      <img className="outer" src="/images/doors/TriangleDoor.svg" alt="Triangle" />
    </div>
  );
};

export default RightDoors;
