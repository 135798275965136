import { BigNumber, ethers } from 'ethers';
import { BetStructOutput } from 'typechain/Roulette';
import { TableBody, TableHeader } from '.';

const Column = ({ bets }: { bets: BetStructOutput[] }) => {
  const total = bets.map((bet) => bet.amount).reduce((prev, current) => prev.add(current), BigNumber.from(0));
  const players = bets.map((bet) => bet.player);

  return (
    <>
      <div className="overflow-x-auto w-full mt-4 rounded-2xl shadow-bet bg-bet-amount-foil p-4">
        <TableHeader betTotal={ethers.utils.formatEther(total.toString())} />
        <TableBody players={players} />
      </div>
    </>
  );
};

export default Column;
