import { LeftDoors, RightDoors } from 'components/specs';
import { useTranslation } from 'react-i18next';
import { BetInfoWrapper } from './BetInfoWrapper';
import './index.css';
import { TheWheel } from './TheWheel';

export interface WheelSpinnerProps {
  items: any[];
  onSelectItem?: (selectedItem: number) => void;
}

const WheelArrow = () => {
  const { t } = useTranslation();
  return <img src="/images/bet_btn.png" alt={t('alt.wheelArrow')} className="wheel-arrow" />;
};

const WheelSpinner = (props: WheelSpinnerProps) => {
  return (
    <div className="w-full absolute left-1/2 transform -translate-x-1/2 justify-center flex flex-col items-center">
      <div className="relative">
        <div className="flex gap-x-24">
          <LeftDoors />
          <div className="relative justify-center flex flex-col flex-shrink-0">
            <div className="">
              <WheelArrow />
              <BetInfoWrapper />
              <TheWheel {...props} />
            </div>
          </div>
          <RightDoors />
        </div>
      </div>
    </div>
  );
};
export default WheelSpinner;
