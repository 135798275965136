import { useWeb3React } from '@web3-react/core';
import ClaimAllButton from 'components/elements/buttons/ClaimAllButton';
import { formatAccount, useIsWalletConnectedBsc } from 'lib/utils/helpers';
import { observer } from 'mobx-react';
import { useStores } from 'providers/StoreProvider';
import { useEffect, useState } from 'react';
import RootStore from 'stores/RootStore';
import { Avatar, Drawer, HeaderButton, LanguagePicker, Logo, WalletButton } from '../elements';
import { SelectWalletModal } from '../specs';

const Header = observer(() => {
  const { chainStore, accountStore }: RootStore = useStores();
  const [show, setShow] = useState(false);
  const isWalletConnectedBsc = useIsWalletConnectedBsc();
  const { account, library, chainId } = useWeb3React();

  useEffect(() => {
    if (account) {
      accountStore.setAccount(account);
      accountStore.setFormattedAccount(formatAccount(account));
    }
    accountStore.setSqmRatePancake();
    if (isWalletConnectedBsc) {
      accountStore.getBalance(account, library, chainId);
    }
  }, [account, accountStore, chainId, chainStore, isWalletConnectedBsc, library]);

  return (
    <>
      <header className="bg-transparent z-10 relative px-1 lg:px-8">
        <div className="navbar">
          <div className="w-smNavSide lg:w-navSide">
            <Logo />
          </div>
          <div className="flex-1 justify-center">
            {isWalletConnectedBsc && (
              <div className="rounded-full border border-nav-edge px-2 py-1">
                <div className="btn-group no-animation">
                  <HeaderButton
                    text={accountStore.formattedAccount}
                    src="/images/icons/metamask.png"
                    alt="metamask address"
                    size="h-xs"
                    className="h-4 min-h-[2rem] lg:h-8 lg:min-h-[3rem] noHover"
                  />
                  <HeaderButton
                    text={(+accountStore.eqxBalance).toFixed(4)}
                    symbol="BNB"
                    src="/images/icons/header_bnb.png"
                    alt="sqm"
                    className="hidden lg:flex noHover"
                  />
                  <HeaderButton
                    text={accountStore.sqmBalance?.toFixed(2)}
                    usdBalance={accountStore.sqmUsd}
                    symbol="SQM"
                    src="/images/icons/sqm_icon_sm.png"
                    alt="bnb"
                    className="hidden lg:flex noHover"
                  />
                  {chainStore.hasClaimWinnings && <ClaimAllButton></ClaimAllButton>}
                </div>
              </div>
            )}
          </div>
          <div className="w-smNavSide lg:w-navSide flex gap-8 justify-end">
            <LanguagePicker />
            {!isWalletConnectedBsc ? (
              <WalletButton onClick={() => setShow(true)} className="flex" />
            ) : (
              <>
                <Drawer
                  account={accountStore.formattedAccount}
                  sqmBalance={accountStore.sqmBalance}
                  sqmUsd={accountStore.sqmUsd}
                  eqxBalance={accountStore.eqxBalance}
                  isWalletConnectedBsc={!!isWalletConnectedBsc}
                  setShow={setShow}
                />
                <div className="btn btn-circle bg-nav-edge hidden lg:flex">
                  <Avatar src="" alt="" username={accountStore.account} />
                </div>
              </>
            )}{' '}
          </div>
        </div>
      </header>
      <SelectWalletModal modalShow={show} setModalView={setShow} />
    </>
  );
});

export default Header;
