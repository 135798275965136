import { makeObservable } from 'mobx';
import AccountStore from './AccountStore';
import ChainStore from './ChainStore';

export class RootStore {
  chainStore: ChainStore;
  accountStore: AccountStore;
  constructor() {
    this.chainStore = new ChainStore(this);
    this.accountStore = new AccountStore(this);
    makeObservable(this, {});
  }
}

export default RootStore;
