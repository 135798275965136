import { useTranslation } from 'react-i18next';
import { LangMenuItemProps } from './LangMenuItem';

export const LangFlag = ({ flag, flagSize = '40px' }: Omit<LangMenuItemProps, 'label' | 'code' | 'onClick'>) => {
  const { t } = useTranslation();
  return (
    <img
      src={`/images/flags/${flag}.svg`}
      alt={`${t('alt.flag_of')} ${flag}`}
      className="mask mask-circle"
      height={flagSize}
      width={flagSize}
    />
  );
};
