import { useTranslation } from 'react-i18next';
import { Avatar } from '..';
import { ButtonPropsX } from '../../../lib/types';

const BetButton = ({ onClick, src, multiplier }: ButtonPropsX) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="hidden lg:flex place-a-bet gap-4 rounded-full w-bet items-center p-1 justify-between">
        {src && <Avatar src={src} alt={t('alt.symbol')} size="h-md" />}
        <span className="hidden lg:block lg:text-3xl leading-relaxed text-black font-gos tracking-tighter">
          {multiplier}
        </span>
        <button
          onClick={onClick}
          className="hidden lg:block btn btn-ghost text-button-surface text-lg hover:opacity-50"
        >
          {t('footer.placeABet')}
        </button>
      </div>

      <div className="flex lg:hidden place-a-bet_small">
        <button onClick={onClick} className="btn btn-ghost text-button-surface gap-2 px-2 hover:opacity-50">
          {src && <Avatar src={src} alt={t('alt.symbol')} size="h-sm" />}
          <span className="text-base font-sans font-thin uppercase">{multiplier}</span>
        </button>
      </div>
    </>
  );
};

export default BetButton;
